<script setup lang="ts">
import type { ParsedContent } from '@nuxt/content'
import SpellTable from '~/components/listings/SpellTable.vue'

const props = defineProps<{
  item: ParsedContent
  condensed?: boolean
}>()

const type = computed(() => {
  if (props.item.isListing) return null
  if (props.item.race && props.item.class) return 'character'
  if (props.item._path?.startsWith('/homebrew/feats/')) return 'feat'
  if (props.item._path?.startsWith('/homebrew/items/')) return 'item'
  if (props.item._path?.startsWith('/homebrew/spells/')) return 'spell'
  if (props.item._path?.startsWith('/history/materials/')) return 'material'
  return null
})
</script>

<template>
  <p v-if="item.subtitle" class="subtitle subtitle--custom">{{ item.subtitle }}</p>
  <CharacterSubtitle v-else-if="type === 'character'" :item="item" :condensed="condensed"></CharacterSubtitle>
  <FeatSubtitle v-else-if="type === 'feat'" :item="item"></FeatSubtitle>
  <ItemSubtitle v-else-if="type === 'item'" :item="item"></ItemSubtitle>
  <MaterialSubtitle v-else-if="type === 'material'" :item="item"></MaterialSubtitle>
  <SpellTable v-else-if="type === 'spell'" :spell="item"></SpellTable>
</template>

<style lang="postcss">
.subtitle {
  font-style: italic;
}

header + .subtitle {
  font-size: 1.125em;
}

h6 + .subtitle {
  display: inline;
  font-style: normal;
  float: left;
  margin: 0 0.25em 0 0;

  &::before {
    content: '(';
  }

  &::after {
    content: '):';
  }
}
</style>
